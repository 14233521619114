:root {
    --fc-today-bg-color: var(--col-content-bg);
}
.maincontentinner .fc-theme-standard td,
.maincontentinner .fc-theme-standard th{
    padding:0px;
}
.maincontentinner .fc .fc-scrollgrid,
.maincontentinner .fc-theme-standard td td {
    border: 1px solid var(--fc-border-color);
}

.fc-view {
    background:var(--kanban-card-bg);
}

.maincontentinner .fc-theme-standard th th{
    border: 1px solid var(--fc-border-color);
    padding:0px;
}

.maincontentinner .fc-theme-standard th th{
    padding:5px;
}

.fc .fc-daygrid-day-number,
.fc .fc-col-header-cell-cushion{
    color:var(--primary-font-color);
}

.maincontentinner .fc-theme-standard td td.fc-timegrid-slot-lane {
    border-bottom:0px;
}

.maincontentinner .fc-theme-standard td td.fc-timegrid-slot-minor {
    border-bottom:0px;
    border-top:0px;
}

.fc-timegrid-slot-label {
    background: var(--col-title-bg);
}

.fc-day-today {
    font-weight:bold;
}

.maincontentinner .fc .fc-timegrid-col.fc-day-today,
.maincontentinner .fc .fc-daygrid-day.fc-day-today{
    background-color:var(--col-title-bg);
}

.maincontentinner .fc .fc-timegrid-axis-cushion,
.maincontentinner .fc .fc-timegrid-slot-label-cushion {
    font-size:var(--font-size-s);

}

