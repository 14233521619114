.kanbanBoard {
    margin-top:10px;
    margin-bottom:40px;
}

.tilt.right {
    transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -webkit-transform: rotate(3deg);
}
.tilt.left {
    transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -webkit-transform: rotate(-3deg);
}

.column {
    box-sizing: border-box;
    height:auto;
    padding:0px 5px;
    flex:1;
    min-width:150px;
}

.column:first-child {
    padding-left:0px;
}
.column:last-child {
    padding-right:0px;
}

.column .contentInner {
    background:var(--kanban-col-bg);
    border:0px solid var(--neutral);
    padding: 5px 0px;
    min-height:45px;
    /*overflow-y:scroll; Danger: when enabled the dropdowns will be hidden in the overflow. */
}

.kanbanCardContent {
    overflow:hidden;
}

.ticketBox:hover {
    background:var(--kanban-card-hover);
}


.ticketBox:hover .kanbanContent:before {
    background: linear-gradient(transparent, var(--main-menu-link-hover-bg) 70% 100%);
}

.ticketBox h4 a {
    font-weight:bold;
}

.column .contentInner .ui-state-highlight {
    background:var(--col-title-bg);
    border: 2px dotted var(--neutral);
    visibility:visible;
    display:block;
    -webkit-transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
}

.portlet {
    margin: 0 1em 1em 0;
    padding: 0.3em;
}
.portlet-header {
    padding: 0.2em 0.3em;
    margin-bottom: 0.5em;
    position: relative;
}
.portlet-toggle {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -8px;
}
.portlet-content {
    padding: 0.4em;
}
.portlet-placeholder {
    border: 1px dotted black;
    margin: 0 1em 1em 0;
    height: 50px;
}

.titleBorderColor1,
.title-border-label-important {
    border-bottom:5px solid var(--red);
}

.title-border-label-danger {
    border-bottom:5px solid var(--dark-red);
}

.titleBorderColor3,
.title-border-label-info{
    border-bottom:5px solid var(--dark-blue);
}

.titleBorderColor4,
.title-border-label-warning{
    border-bottom:5px solid var(--yellow);
}

.titleBorderColor2{
    border-bottom:5px solid var(--yellow);
}

.titleBorderColor0,
.title-border-label-success{
    border-bottom:5px solid var(--green)
}

.titleBorderColor-1,
.title-border-label-default{
    border-bottom:5px solid var(--grey);
}

.title-border-label-purple{
    border-bottom:5px solid var(--purple);
}

.title-border-label-pink{
    border-bottom:5px solid var(--pink);
}

.title-border-label-brown{
    border-bottom:5px solid var(--brown);
}

.title-border-label-blue{
    border-bottom:5px solid var(--blue);
}

.title-border-label-dark-blue{
    border-bottom:5px solid var(--dark-blue);
}

.title-border-label-darker-blue{
    border-bottom:5px solid var(--darker-blue);
}

.title-border-label-green{
    border-bottom:5px solid var(--green);
}

.title-border-dark-green{
    border-bottom:5px solid var(--dark-green);
}

.title-border-red{
    border-bottom:5px solid var(--red);
}

.title-border-dark-red{
    border-bottom:5px solid var(--dark-red);
}

.title-border-grey{
    border-bottom:5px solid var(--grey);
}

.sortableTicketList li{
    list-style:none;
}

.sortableTicketList li:hover {
    background:var(--kanban-card-hover);
}

.sortableTicketList li.no-hover:hover {
    background:var(--kanban-card-bg);
}


.ticketBox {
    background: var(--kanban-card-bg);
    border:none;
    border-left:3px solid transparent;
    border-right: 3px solid transparent;
    padding:10px 12px; /* account for 3px border */
    padding-bottom:10px;
    margin-bottom:10px;
    cursor:move;
    border-radius:var(--box-radius);
    box-shadow: var(--regular-shadow);
}

.sortableTicketList.disabled .ticketBox {
    cursor:auto;
}

.ticketBox textarea {
    max-width:100%;
}
.ticketBox.fixed {
    cursor:default;
}

.sortableTicketList li:hover .ticketBox {
    background:var(--kanban-card-hover);
}

.sortableTicketList li:hover .ticketBox.fixed {
    background:var(--kanban-card-hover);
}

.sortableTicketList li.no-hover:hover .ticketBox.fixed {
    background: var(--kanban-card-bg);
}

.ticketBox p.description {
    padding-bottom:10px;
    overflow:hidden;
}

.ticketBox h6,
.ticketBox h6 a {
    line-height:16px;
}

.ticketBox h3,
.ticketBox h3 a{
    font-size:var(--font-size-l);
}

.ticketBox .label-important,
.ticketBox .label-warning,
.ticketBox .label-success,
.ticketBox .label-info,
.ticketBox .label-inverse,
.ticketBox .label-default,
.ticketBox .label-primary{
    padding-top:0px;
    padding-bottom:0px;
}

 .ticketBox .timerContainer .btn{
    padding-top:1px;
    padding-bottom:1px;
}

.sortableTicketList .empty {
    text-align:center;
    color:#999;
    padding:20px 0px;
}


body .maincontent .priority-border-1,
body .maincontent .commentStatus-red{
    border-left: 3px solid var(--dark-red)
}

body .maincontent .priority-border-2 {
    border-left: 3px solid var(--red);
}

body .maincontent .priority-border-3,
body .maincontent .commentStatus-yellow{
    border-left: 3px solid var(--yellow)
}

body .maincontent .priority-border-4,
body .maincontent .commentStatus-green{
    border-left: 3px solid var(--green);
}

body .maincontent .priority-border-5 {
    border-left: 3px solid var(--dark-grey);
}

body .maincontent .priority-bg-1,
body .maincontent .ticketDropdown .priority-bg-1{
    background:var(--dark-red);
    color:var(--dark-red-text-color);
}

body .maincontent .priority-bg-2,
body .maincontent .ticketDropdown .priority-bg-2{
    background: var(--red);
    color:var(--red-text-color);
}

body .maincontent .priority-bg-3,
body .maincontent .ticketDropdown .priority-bg-3{
    background: var(--yellow);
    color:var(--yellow-text-color);
}

body .maincontent .priority-bg-4,
body .maincontent .ticketDropdown .priority-bg-4{
    background:var(--green);
    color:var(--green-text-color);
}

body .maincontent .priority-bg-5,
body .maincontent .ticketDropdown .priority-bg-5{
    background:var(--dark-grey);
    color:var(--dark-grey-text-color);
}


body .maincontent .priority-text-1,
body .maincontent .priority-text-1 a,
body .maincontent .priority-text-1 a:link{
    color:var(--dark-red);
}

body .maincontent .priority-text-2,
body .maincontent .priority-text-2 a,
body .maincontent .priority-text-2 a:link {
    color:var(--red);
}

body .maincontent .priority-text-3,
body .maincontent .priority-text-3 a,
body .maincontent .priority-text-3 a:link {
    color:var(--yellow);
}

body .maincontent .priority-text-4,
body .maincontent .priority-text-4 a,
body .maincontent .priority-text-4 a:link {
    color:var(--green);
}

body .maincontent .priority-text-5,
body .maincontent .priority-text-5 a,
body .maincontent .priority-text-5 a:link {
    color:var(--dark-grey);
}

.widgettitle {
    background: var(--kanban-col-title-bg) !important;
    color: var( --kanban-col-title-color);
    padding: 10px 15px;
    font-size: var(--base-font-size);
    line-height:17px;
    font-weight: bold;
    border-bottom-width:3px;
    border-top-left-radius: var(--box-radius);
    border-top-right-radius: var(--box-radius);
    box-shadow: var(--regular-shadow)
}

.widgettitle span {
    vertical-align: top;
    display: inline-block;
    margin-right: 8px;
}

.sortableTicketList .row-fluid {
    width:100%;
    display:flex;
    padding-bottom: 20px;
}

.widgettitle strong {
    line-height:normal;
}

.kanbanContent {
    overflow:hidden;
    max-height:75px;
    position:relative;
}

.kanbanContent:before {
    content: '';
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    bottom: 0;

}

.kanbanLane {
    border-bottom: 1px solid var(--neutral);
    color: var(--kanban-col-title-color);
    padding: 10px 10px;
    font-size: var(--base-font-size);
    padding-top:0px;
}

.kanbanBoard .ticketDropdown a.dropdown-toggle {
    font-size: var(--base-font-size);
    max-width: 145px; /*min column width is 150px*/
    overflow: hidden;
    text-overflow: ellipsis;
}


.sortableTicketList {
    position:relative;
}

.sortableTicketList .ticketBox.priority-hightlight {
    position:relative;
    background:#fff;
    box-shadow: var(--regular-shadow);
}


.sortableTicketList .ticketBox.priority-border-.priority-hightlight {
    box-shadow: 0px 0px 7px 2px var(--grey);
}

.sortableTicketList .ticketBox.priority-border-1.priority-hightlight {
    box-shadow: 0px 0px 7px 2px var(--dark-red);
}

.sortableTicketList .ticketBox.priority-border-2.priority-hightlight {
    box-shadow: 0px 0px 7px 2px var(--red);
}

.sortableTicketList .ticketBox.priority-border-3.priority-hightlight {
    box-shadow: 0px 0px 7px 2px var(--yellow);
}

.sortableTicketList .ticketBox.priority-border-4.priority-hightlight {
    box-shadow: 0px 0px 7px 2px var(--green);
}

.sortableTicketList .ticketBox.priority-border-5.priority-hightlight {
    box-shadow: 0px 0px 7px 2px var(--grey);
}

.sortableTicketList .ticketBox.active {
    box-shadow: var(--regular-shadow);
    font-weight: bold;
}

/*

@keyframes pulse-bg-1 {
    0% {box-shadow: var(--regular-shadow), 0 0 0 0 rgba(130, 18, 25, 0.7);}
    70% {box-shadow: var(--regular-shadow), 0 0 5px 5px rgba(130, 18, 25, 0);}
    100% {box-shadow:var(--regular-shadow), 0 0 0 0 rgba(130, 18, 25, 0);}
}
@keyframes pulse-bg-2 {
    0% {box-shadow: var(--regular-shadow), 0 0 0 0 rgba(187, 27, 37, 0.7);}
    70% {box-shadow: var(--regular-shadow), 0 0 5px 5px rgba(187, 27, 37, 0);}
    100% {box-shadow:var(--regular-shadow), 0 0 0 0 rgba(187, 27, 37, 0);}
}
@keyframes pulse-bg-3 {
    0% {box-shadow: var(--regular-shadow), 0 0 0 0 rgba(253,184,53, 0.7);}
    70% {box-shadow: var(--regular-shadow), 0 0 5px 5px rgba(130, 18, 25, 0);}
    100% {box-shadow:var(--regular-shadow), 0 0 0 0 rgba(130, 18, 25, 0);}
}
@keyframes pulse-bg-4 {
    0% {box-shadow: var(--regular-shadow), 0 0 0 0 rgba(50, 150, 93, 0.7);}
    70% {box-shadow: var(--regular-shadow), 0 0 5px 5px rgba(50, 150, 93, 0);}
    100% {box-shadow:var(--regular-shadow), 0 0 0 0 rgba(50, 150, 93, 0);}
}
@keyframes pulse-bg-5 {
    0% {box-shadow: var(--regular-shadow), 0 0 0 0 rgba(112, 112, 112, 0.7);}
    70% {box-shadow: var(--regular-shadow), 0 0 5px 5px rgba(112, 112, 112, 0);}
    100% {box-shadow:var(--regular-shadow), 0 0 0 0 rgba(112, 112, 112, 0);}
}




.sortableTicketList .ticketBox.priority-hightlight:before,
.sortableTicketList .ticketBox.priority-hightlight:after {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    background: linear-gradient(16deg, #fff, #1b75bb, #fff, #fff, #fff);
    background-size: 400%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
    animation: steam 30s linear infinite;
    border-radius:7px;
}

@keyframes steam {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 400% 0;
    }
    100% {
        background-position: 0 0;
    }
}

.sortableTicketList .ticketBox.priority-hightlight:after {
    filter: blur(8px);
    animation-delay: 2s;
}

 */

.leader-line {
    z-index:20;
}
