




a, a:link {
    color: var(--blue);
}

h4 {
    font-size: var(--base-font-size);
}

html,
body.loginpage {
    height:100%;
}



body {
    height: 100%;
    background:var(--main-bg);
}

body {
    font-size: var(--base-font-size);
    font-family: var(--primary-font-family);
}

body.loginpage {
    background: var(--main-bg);
}

/* TOOLS */
.f-right {
    float: right !important;
}

.f-left {
    float: left !important;
}

.clear {
    clear: both !important;
}

.clearall {
    clear: both !important;
}

.a-center {
    text-align: center;
}

.a-right {
    text-align: right;
}

.a-left {
    text-align: left;
}

/* Templates */
.head-icon {
    font-size: 50px;
}

.ui-tabs-panel {
    min-height: 150px;
}

/* Action for tabwidget fix */
.tabbedwidget .btn-group {
    position: relative;
    top: 4px;
    right: 15px;
    float: right;
}

.tabbedwidget .dropdown-menu a {
    color: #555;
}

.tabbedwidget .dropdown-menu {
    right: 0;
    left: auto;
}

/* Forms */


.half {
    width: 49%;
    margin-right: 1%;
    float: left;
}

.ui-spinner-button {
    padding: 2px 0;
}


.uneditable-input {
    width: 175px !important;
}



/* Time */
#headClock {
    color: #555;
    font-size: var(--base-font-size);
}

/* widgets */
#notes .note {
    margin: 15px 0;
    padding: 10px 0;
    border-top: 1px dotted #ccc;
}

.dropdown-menu .checker {
    float: left;
    clear: both;
}

.dropdown-menu {
    min-width: 240px;
}

/* messages */
#compose .field {
    margin-left: 25px;
}

#compose {
    padding: 10px 0;
}

/* .isotope .isotope-item { width: 150px; height: 100px; overflow: hidden; float: left; display: block; } */
.cboxElement {
    width: 100px;
    height: 100px;
    overflow: hidden;
    display: block;
    text-align: center;
}

.cboxElement img {
    display: block;
    margin: auto;
    margin-bottom: 10px;
}

#medialist > li {
    float: left;
}

#medialist > li .nav-header {
    padding: 3px 15px;
}

.mediamgr_category ul {
    max-width: 200px;
    overflow-y: auto;
}

.mediamgr_content {
    width: 100%;
    margin: 0;
}

/*** LOGIN PAGE ***/

.loginpanel {
    position: absolute;
    top: 30%;
    left: 50%;
    height: 300px;
    transform: translate(-50%, -50%);
}

.loginpage .pagetitle h1 {
    font-size:32px;
}

.loginpanelinner {
    padding: 20px;
    border: 8px solid #555;
}

.loginpanelinner .logo {
    text-align: center;
    padding: 20px 0;
}

.inputwrapper input {
    border: 0;
    padding: 10px;
    background: #fff;
    width: 100%;
}

.inputwrapper input:active, .inputwrapper input:focus {
    background: #fff;
    border: 0;
}

.loginpanel .inputwrapper button {
    display: block;
    border: 1px solid #555;
    padding: 10px;
    background: #555;
    width: 100%;
    color: #f3f4f5;
    text-transform: uppercase;
}

.loginpanel .inputwrapper button:focus,
.loginpanel .inputwrapper button:active,
.loginpanel .inputwrapper button:hover {
    background: #ccc;
    color: #555;
    border: 1px solid #ccc
}

.inputwrapper label {
    display: inline-block;
    margin-top: 10px;
    color: rgba(255, 255, 255, 0.8);
    font-size: var(--base-font-size);
    vertical-align: middle;
}

.inputwrapper label input {
    width: auto;
    margin: -3px 5px 0 0;
    vertical-align: middle;
}

.inputwrapper .remember {
    padding: 0;
    background: none;
}

.login-alert, .login-success {
    display: none;
}

.login-alert .alert, .login-success .alert {
    font-size: var(--base-font-size);
    text-align: center;
    padding: 5px 0;
    border: 0;
}

.loginfooter {
    font-size: var(--base-font-size);
    color: rgba(255, 255, 255, 0.5);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 5px 0;
}




.row .regLeft {
    position:relative;
    z-index:3;
    height:100%;
    overflow:hidden;
    display: flex;
    align-items: center;
    padding-left:100px;
    color:var(--accent1-color);
    background:linear-gradient(45deg, var(--accent1) 20%,var(--accent2) 100%);
    box-shadow: var(--large-shadow);
}
.row .regLeft .mainWelcome {
    font-family: var(--primary-font-family);
    font-size: 54px;
    padding-left:15px;
    font-weight:400;
}



.row .regLeft::after {
    background:url(../../images/03-1.png) no-repeat 50px 0px;
    top:0px;
    left:0px;
    content:"";
    opacity:0.2;
    position:absolute;
    width: 250px;
    height: 326px;

}

.row .regRight {
    position:relative;
    z-index:10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.row .regRight img {
    max-width:200px;
    margin-bottom:40px;
    margin-top:-40px;
}



.iq-objects-04 {
    position: absolute;
    top: 150%;
    right: -50%;
    border: 56px solid rgba(255,255,255, 0.4);
    border-radius: 900px;
    height: 600px;
    width: 600px;
    z-index:5;
}

.iq-fadebounce {
    -webkit-animation-name: fadebounce;
    -moz-animation-name: fadebounce;
    -ms-animation-name: fadebounce;
    -o-animation-name: fadebounce;
    animation-name: fadebounce;
    -webkit-animation-duration: 3s;
    -moz-animation-duration: 3s;
    -ms-animation-duration: 3s;
    -o-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

@-moz-keyframes fadebounce {
    0% {
        -moz-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }

    50% {
        -moz-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 1
    }

    100% {
        -moz-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }

}

@-webkit-keyframes fadebounce {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }

    50% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 1
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }

}

@-o-keyframes fadebounce {
    0% {
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }

    50% {
        -o-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 1
    }

    100% {
        -o-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }

}

@-ms-keyframes fadebounce {
    0% {
        -ms-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }

    50% {
        -ms-transform: translateY(20px);
        transform: translateY(20px);
        opacity: 1
    }

    100% {
        -ms-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }

}

@keyframes fadebounce {
    0% {
        transform: translateY(0);
        opacity: 1
    }

    50% {
        transform: translateY(20px);
        opacity: 1
    }

    100% {
        transform: translateY(0);
        opacity: 1
    }

}

.regpanelinner .pageheader {
    padding:0;
    border-bottom: 0px solid #ddd;
    position: relative;
    color:var(--main-action-bg);
    background:none;
}

.regpanelinner .pageheader .pagetitle h1,
.regpanelinner .pageheader .pageicon {
    color:var(--main-action-bg);
    border-color:var(--main-action-bg);
}

.regpanelinner #login,
.regpanelinner #resetPassword{
}

.regpanelinner #login .btn,
.regpanelinner #resetPassword .btn{
    width:100%;
    margin-bottom:25px;
}

.regpanelinner input {
    width:100%;
}

.regcontent {
    width:400px;
    padding-top:25px;
}

.regcontent a.forgotPw  {
    color:var(--primary-font-color);
}

.forgotPwContainer {
    text-align:right;
    padding-bottom:20px;
}

.login-alert .alert-danger, .login-alert .alert-error {
    background: var(--secondary-background);

    border-left: 5px solid var(--dark-red);
    padding: 5px;
    border-radius: var(--element-radius);
    box-shadow: var(--large-shadow);
    color: var(--primary-font-color);
    font-size: var(--base-font-size);
}

.login-alert .alert-success {
    background: var(--secondary-background);
    border-left: 5px solid var(--green);
    padding: 5px;
    border-radius: var(--element-radius);
    box-shadow: var(--large-shadow);
    color: var(--primary-font-color);
    font-size: var(--base-font-size);
}

.login-alert .alert-warning{
    background: var(--secondary-background);
    border-left: 5px solid var(--yellow);
    padding: 5px;
    border-radius: var(--element-radius);
    box-shadow: var(--large-shadow);
    color: var(--primary-font-color);
    font-size: var(--base-font-size);
}

.login-alert .alert-info{
    background: var(--secondary-background);
    border-left: 5px solid var(--blue);
    padding: 5px;
    border-radius: var(--element-radius);
    box-shadow: var(--large-shadow);
    color: var(--primary-font-color);
    font-size: var(--base-font-size);
}

.bigAlert .alert {
    font-size: var(--font-size-xl);
    padding: 10px;
    border-left-width:10px;
}

/*** HEADER ***/

.viewmore a {
    font-size: var(--base-font-size);
    text-transform: uppercase;
    font-size: var(--base-font-size) !important;
}

.newusers {
    min-width: 200px;
}

.newusers li a:hover {
    background: #f3f4f5;
}

.newusers .userthumb {
    width: 35px;
    display: block;
    float: left;
    margin-right: 10px;
}

.newusers strong {
    display: block;
    line-height: normal;
}

.newusers small {
    color: #999;
    line-height: normal;
}



/*** LEFT ***/





/*** MAIN PANEL ***/


.pageheader .padding-top {
    padding-top:0px;
    line-height:60px;
}

.pageheader .headerCTA {
    float:right;
    line-height:60px;
    color:var(--main-action-color);
}

.pageheader .backBtn {
    color:var(--main-action-color);
}

.pageicon {
    width: 30px;
    height:30px;
    font-size:16px;
    padding: 0px;
    color: var(--main-titles-color);
    border-width: 2px;
    border-style: solid;
    border-color:var(--main-titles-color);
    display: inline-block;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    border-radius: 30px;
    float: left;
    text-align: center;
    margin-right:5px;
    line-height:26px;
    margin-top:15px;
}



.searchbar {
    position: absolute;
    top: 33%;
    right: 20px;
}



.tab-primary.ui-tabs {
    background:transparent;
}

.tab-primary.ui-tabs .ui-tabs-nav {
    background:transparent;
    border-bottom-width: 1px;
    border-bottom-color: var(--main-border-color);
    border-bottom-style: solid;

}

.ui-tabs-panel {
    background:transparent;
}

.widget,
.tabbedwidget {
    border:none;
}

.tab-primary.ui-tabs .ui-tabs-nav {
    height:40px;
}



.tab-primary.ui-tabs .ui-tabs-nav li a,
.ui-tabs-nav > li a {
    color:var(--main-action-bg);
    background:none;
}



.tab-primary.ui-tabs .ui-tabs-nav li a:hover,
.ui-tabs-nav > li a:hover {
    background:var(--main-menu-link-hover-bg);
    color:var(--main-menu-link-hover-color);

}

.tab-primary.ui-tabs .ui-tabs-nav > li {
    border-right:0px;
}

.tab-primary.ui-tabs .ui-tabs-nav > li a {
    line-height:38px;
    border-bottom:2px solid transparent;
    border-top:0px solid #fff;
    padding:0px 15px;
    color:var(--main-action-bg);
    font-weight:bold;
}

body .tab-primary.ui-tabs .ui-tabs-nav li.ui-state-active a {
    border-bottom:2px solid var(--main-action-bg);
    color:var(--tab-active-color);
    background: var(--tab-active-background);
    border-radius: 5px 5px 0px 0px;
}

.tab-primary.ui-tabs .ui-tabs-nav > li a:hover {
    background:var(--tab-active-background);
}




.widgetcontent {
    background: var(--secondary-background);
    padding: 15px 12px;
    border: 1px solid var(--neutral) !important;
    border-top: 0;
    margin-bottom: 20px;

}

.widgetcontent p {
    margin: 15px 0;
}

.widgetright {
    width: 300px !important;
    margin-left: 15px !important;
    float: right;
}

.widgetleft {
    width: 300px !important;
    margin-right: 15px;
    float: left;
}

.widgettitle.title-secondary {
    background:none;
    color:#555;
    background:#f0f0f0;
    border-left:1px solid #ccc;
    border-right:1px solid #ccc

}

.wt1 {
    background: #555;
    color: #fff;
}

.wc1 {
    border-color: #555;
}

.boxedHighlight{
    display:block;
    color:#fff;
    padding:10px;
    width:100%;
    margin-bottom:25px;
}

.boxedHighlight .value {
    padding-top:10px;
    padding-bottom:10px;
    font-size:36px;
    display:block;
    font-weight:lighter;

}

/* Tiny MCE */
#description_parent iframe {
    width: 600px !important;
}

/*** DASHBOARD ***/

.commentlist {
    list-style: none;
    clear: both;
}

.commentlist li {
    display: block;
    border-bottom: 1px solid #ddd;
    padding: 15px;
}

.commentlist li img {
    width: 60px;
}

.commentlist li .comment-info {
    margin-left: 80px;
}

.commentlist li p:last-child {
    margin-bottom: 0;
}

.commentlist li .btn {
    margin-right: 3px;
}

.commentlist li .btn span {
    margin-right: 5px;
}

.tabtitle {
    padding: 10px;
    font-size: var(--base-font-size);
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 5px;
    border-bottom: 1px solid #ddd;
}

.userlist {
    list-style: none;
}

.userlist li {
    display: block;
    border-bottom: 1px solid #ddd;
    padding: 10px;
    cursor: pointer;
}

.userlist li:hover {
    background-color: #f7f7f7;
}

.userlist li:last-child {
    border-bottom: 0;
}

.userlist li .uinfo {
    margin-left: 60px;
}

.userlist li img {
    display: block;
    width: 50px;
}

.userlist li .uinfo h5 {
    font-size: var(--font-size-l);
    color: var(--primary-color);
    margin-bottom: 2px;
}

.userlist li .uinfo span {
    display: block;
    font-size: var(--base-font-size);
    line-height: 16px;
    color: #999;
}

.userlist li .uinfo span.pos {
    text-transform: uppercase;
    color: #666;
}

.userlist li .par {
    font-size: var(--base-font-size);
    line-height: normal;
    margin-top: 4px;
}

.userlist-favorites li {
    cursor: default;
}

.userlist-favorites li:hover {
    background: none;
}

.userlist-favorites li .link {
    font-size: var(--base-font-size);
    margin-top: 7px;
}

.userlist-favorites li .link a {
    color: #666;
    display: inline-block;
    padding: 4px 5px;
    line-height: normal;
}

.userlist-favorites li .link a:last-child {
    background: #86c91d;
    color: #fff;
}

.userlist-favorites li .link a:first-child {
    background: #e9c72a;
    color: #fff;
}

.userlist-favorites li .link a:hover {
    text-decoration: none;
    color: #fff;
}

/*** HEADERS AND BOXES ***/

.title-danger {
    background-color: #dd0000 !important;
}

.title-warning {
    background-color: #FCB904 !important;
}

.title-success {
    background-color: #86D628 !important;
}

.title-info {
    background-color: #71B8EE !important;
}

.title-inverse {
    background-color: #555 !important;
}


.headtitle-box {
    margin-bottom: 0;
}

.headtitle .btn-group {
    float: right;
}

.headtitle .btn-group .btn, .headtitle .btn-group .btn:focus,
.headtitle .btn-group.open .btn.dropdown-toggle {
    background: none;
    border: 0;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
    padding: 11px 20px;
}

.headtitle .dropdown-menu {
    left: auto;
    right: 0;
}

.headtitle .btn-group .btn .caret {
    border-top: 4px solid #fff;
}

.widgetbox .headtitle {
    margin-bottom: 0;
}

.box-danger .widgettitle {
    background-color: #dd0000;
}

.box-danger .widgetcontent {
    border-color: #dd0000;
}

.box-warning .widgettitle {
    background-color: #FCB904;
}

.box-warning .widgetcontent {
    border-color: #FCB904;
}

.box-success .widgettitle {
    background-color: #86D628;
}

.box-success .widgetcontent {
    border-color: #86D628;
}

.box-info .widgettitle {
    background-color: #71B8EE;
}

.box-info .widgetcontent {
    border-color: #71B8EE;
}

.box-inverse .widgettitle {
    background-color: #555;
}

.box-inverse .widgetcontent {
    border-color: #555;
}




/*** BUTTONS & ICONS ***/

.fontawesomeicons ul {
    list-style: none;
}

.fontawesomeicons ul li {
    line-height: 25px;
}

.iconsweetslist {
    list-style: none;
}

.iconsweetslist li {
    float: left;
    line-height: 26px;
    width: 25%;
}


.menuright {
    list-style: none;
}

.menuright li {
    display: block;
    margin-bottom: 1px;
}

.menuright li a {
    display: block;
    padding: 5px 10px;
    color: #666;
}

.menuright li a:hover {
    background: #ddd;
    text-decoration: none;
}

.menuright li.current a {
    background: #555;
    color: #fff;
}

.listfile {
    list-style: none;
}

.listfile > li {
    display: inline-block;
    margin: 5px 10px 5px 0;
    border: 1px solid var(--main-border-color);
    padding: 10px;
    border-radius: var(--box-radius);
    background: var(--secondary-background);
}

.listfile > li:hover {
    border-color: #bbb;
}

.listfile > li a {
    display: block;
    text-align:center;
}

.listfile > li a:hover {
    cursor: pointer;
}

.listfile > li span.filename {
    display: block;
    margin-top: 5px;
    font-size: var(--base-font-size);
    text-align: center;
}

.listfile > li.selected {
    border-color: var(--dark-blue);
    background: var(--blue);
}


.imgpreview {
    width: 249px;
    max-width: none;
}

.imginfo {
    background: #f3f4f5;
    padding: 10px 20px 10px 10px;
    border: 1px solid #ddd;
}

.imgdetails label {
    display: block;
    margin-bottom: 2px;
}

.imgdetails input, .imgdetails textarea {
    padding: 7px 5px;
    border: 1px solid #bbb;
    background: #fcfcfc;
}




/***  TYPOGRAPHY ***/

ul.list-unordered, ol.list-ordered,
ul.list-unordered ul, ol.list-ordered ol {
    margin: 0 0 0 25px;
    padding: 0;
}

ul.list-checked, ol.list-checked,
ul.list-checked2, ol.list-checked2 {
    list-style: none;
    margin: 0;
}

ul.list-checked li, ol.list-checked li {
    padding-left: 25px;
    display: block;
}

ul.list-checked2 li, ol.list-checked2 li {
    padding-left: 25px;
    display: block;
}

ul.list-nostyle ul, ol.list-style ol,
ul.list-nostyle ol, ol.list-style ul {
    margin: 0 0 0 25px;
    padding: 0;
}

ul.list-nostyle li, ol.list-nostyle li {
    list-style: none;
}

ul.list-nostyle li span, ol.list-nostyle li span {
    vertical-align: top;
}

ul.list-inline li {
    display: inline-block;
    margin: 0 5px 10px 0;
}



.sidebarlabel {
    margin-bottom: 5px;
}

/*** ERROR PAGE ***/

.errortitle {
    text-align: center;
    margin-top: 5%;
}

.errortitle h4 {
    font-size: 24px;
    margin-bottom: 20px;
}

.errortitle span {
    display: inline-block;
    font-size: 120px;
    background: #555;
    color: #fff;
    line-height: normal;
    padding: 10px 30px;
    margin-left: 7px;
}

.errortitle .errorbtns {
    margin-top: 20px;
}

.errortitle .errorbtns a {
    margin-right: 7px;
    display: inline-block;
}




.nopadding {
    padding: 0;
}

.padding5 {
    padding: 5px;
}

.padding10 {
    padding: 10px;
}

.padding15 {
    padding: 15px;
}

.padding20 {
    padding: 20px;
}

.divider15 {
    clear: both;
    height: 15px;
}

.divider30 {
    height: 30px;
}

.marginleft15 {
    margin-left: 15px;
}

.marginleft5 {
    margin-left: 5px;
}

.tooltipflot {
    background: #555;
    color: #fff;
    font-size: var(--base-font-size);
    padding: 2px 10px;
}



.topbar {
    display: none;
}

.btn-group+.btn-group {
    margin-left: 0px;

}


.pagetitle h5 {
    display:none;
}


.subtitle2, .table th, .msglist li h4,
.tabbedwizard .stepContainer h4, dt,
.userloggedinfo .userinfo h5,
.loginpanel .inputwrapper button,
.userlist li .uinfo h5 {

    font-weight: normal;
}

/*** TRANSITION ***/

.headmenu-label,
.headmenu-icon, .headmenu .count,
.leftmenu .nav-tabs a,
.dropdown-menu a,
.shortcuts li a,
.userloggedinfo ul li a,
.inputwrapper input, .inputwrapper button,
.quickAddLink, .btn, .dropdown-toggle,
.headmenu a,
.tab-primary.ui-tabs .ui-tabs-nav li,
.tab-primary.ui-tabs .ui-tabs-nav li a,
.sortableTicketList li,
.sortableTicketList li .ticketBox {
    -moz-transition: background-color 0.2s ease-in-out 0s;
    -webkit-transition: background-color 0.2s ease-in-out 0s;
    transition: background-color 0.2s ease-in-out 0s;
}

/*** BOOTSTRAP OVERRIDE ***/

.close {
    text-shadow: 1px 1px rgba(255, 255, 255, 0.4);
}


.dropdown .dropdown-menu .nav-header {
    padding-left: 10px;
    padding-right: 10px;
}

.nav-tabs > .active > a,
.nav-tabs > .active > a:hover,
.nav-tabs > .active > a:focus {
    background-color: var(--primary-color);
    color: #fff;
}

select, textarea, input[type="text"], input[type="password"], input[type="datetime"],
input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"],
input[type="week"], input[type="number"], input[type="email"], input[type="url"],
input[type="search"], input[type="tel"], input[type="color"], .uneditable-input,
.input-append input, .input-prepend input, .input-append select, .input-prepend select,
.input-append .uneditable-input, .input-prepend .uneditable-input,
.input-append .dropdown-menu, .input-prepend .dropdown-menu, .input-append .popover,
.input-prepend .popover, .btn-group > .btn, .btn-group > .dropdown-menu, .btn-group > .popover,
.input-append .add-on, .input-prepend .add-on {
    font-size: var(--base-font-size);
}

.radio input[type="radio"], .checkbox input[type="checkbox"] {
    margin: 0;
}

.fileupload-new .input-append .btn-file {
    -moz-border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
}


.fileupload .btn {
    vertical-align: top;

}

.fileupload .btn-primary {
    color: #fff;
}

.btn-group > .btn + .dropdown-toggle {
    padding-left: 3px;
}

.input-append .add-on, .input-prepend .add-on {
    height: 22px;
}

.bootstrap-timepicker-widget a.btn, .bootstrap-timepicker-widget input {
    -moz-border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
}

.tabbable > .nav-tabs {
    background: var(--primary-color);
    margin: 0;
    border: 1px solid var(--primary-color);
    border-bottom: 0;
    height: 40px;
}

.tabbable > .nav-tabs > li {
    float: left;
    margin: 0;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.tabbable > .nav-tabs > li > a {
    color: #fff;
    border: 0;
    padding: 10px 20px;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    margin: 0;
}

.tabbable > .nav-tabs > li > a:hover {
    background: rgba(255, 255, 255, 0.1);
}

.tabbable > .nav-tabs > li.active > a {
    background: #fff;
    color: var(--primary-color);
    border: 0;
    padding-botom: 12px;
}

.tabbable > .tab-content {
    margin-top: 0;
    border: 1px solid var(--primary-color);
    border-top: 0;
    background: #fff;
    padding: 20px;
}

.nav-tabs.nav-stacked > li:first-child > a,
.nav-tabs.nav-stacked > li:last-child > a {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.nav-tabs.nav-stacked > li > a,
.nav-tabs.nav-stacked > li > a:hover,
.nav-tabs.nav-stacked > li > a:hover,
.nav-tabs.nav-stacked > li > a:focus {
    border-color: var(--primary-color);
}



.nav-list {
    border: 2px solid #555;
    background: #fff;
}

.nav-list .nav-header {
    padding: 7px 15px;
    background: #555;
    color: #fff;
    text-shadow: none;
    font-weight: normal;
}

.nav-list > li > a {
    padding: 7px 15px;
}

.nav-list > .active > a,
.nav-list > .active > a:hover,
.nav-list > .active > a:focus {
    background: var(--primary-color);
}








.pagination {
    margin: 10px 0;
}

.pagination > ul > li a {
    border-color: #ccc;
}

.pagination-large ul > li:first-child > a,
.pagination-large ul > li:first-child > span,
.pagination-large ul > li:last-child > a,
.pagination-large ul > li:last-child > span {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.pagination ul > li > a, .pagination ul > li > span {
    color: #666;
}

.pager li > a {
    border-color: #ccc;
}

.pager li > a, .pager li > span {
    color: #666;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-width: 2px;
}

.label {
    -moz-border-radius: var(--box-radius-small);
    -webkit-border-radius: var(--box-radius-small);
    border-radius:  var(--box-radius-small);
    font-size: var(--font-size-xs);
    text-shadow: none;
    font-weight: normal;
    text-transform: uppercase;
    padding: 2px 5px;
}

.badge {
    font-size: 10px;
    text-shadow: none;
    font-weight: normal;
    line-height: 19px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
}

.modal {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 20px;
    padding: 34px 40px 20px 35px;
}

.modal-header {
    padding:0px;
    border:none;
}

.modal-body {
    padding:0px;
}

.modal-footer .btn {
    margin: 0;

}

.modal-footer .btn.btn-default {
}

.modal-header .close {
    margin-top: 0px;
    font-size: 30px;
}

.modal-footer {
    border-radius: 0px 0px 20px 20px;
    border:none;
    background:var(--secondary-background);
    text-align:left;
    padding:0px;
    padding-top:10px;
}

.modal.fade {

}

.modal-title {
    font-size: var(--font-size-xxl);
    color: var(--primary-font-color);
    margin-bottom: 20px;
    font-weight:normal;
}

.popover, .popover-title {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.popover {
    padding: 0;
}

.alert .close {
    right: -25px;
    top: 1px;
}




.alert {
    border-color: #ccc;
    color: #555;
    margin-bottom: 15px;
    background-color: #fff;
    padding: 0px;
}

.alert-content {
    padding: 8px 35px 0px 14px
}

.infoBox {
    color: #fff;
    font-size: 40px;
    padding: 20px;
    line-height: 40px;
    float: left;
    margin-right: 20px;
}

.alert .close {
    top: 5px;
    right: 10px;
    color: #937f0e;
}

.alert h4 {
    color: #9c6c38;
    padding-bottom: 5px;
}

.alert-error {

}

.alert-error .close, .alert-error h4 {
    color: var(--dark-red);
    font-size: var(--font-size-xl);
}

.alert-error .infoBox {
    background: var(--dark-red);
}

.alert-success {

}

.alert-success .close,
.alert-success h4 {
    color: var(--green)
}

.alert-success .infoBox {
    background: var(--green)
}

.alert-info {

}

.alert-info .close,
.alert-info h4 {
    color: var(--blue);
}

.alert-info .infoBox {
    background: var(--blue);
}

pre.prettyprint, .accordion {
    margin-bottom: 0;
}

dl {
    margin-bottom: 15px;
}

.input-block-level {
    min-height: 37px;
}

table td.center, table th.center {
    text-align: center;
}


.hidden-gt-sm {
    display: none !important;
}



/* Leantime Changes */
.box-right {
    float: right;
    clear: right;
    width: 220px;
    padding-right: 15px;
}



.column-filter-widget {
    float: left;
    padding-right: 10px;
}

.column-filter-widget select {
    width: 100px;
}

.column-filter-widgets {
    background: #f3f4f5;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    height: 77px;
    padding-left: 10px;
}

.column-filter-widget-selected-terms {
    margin-bottom: 15px;
    clear: both;
}

a.filter-term {
    background: none repeat scroll 0 0 #ddd;
    border: 1px solid #ccc;
    color: #555 !important;
    display: block;
    float: left;
    font-size: var(--base-font-size);
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2px 5px;
    text-decoration: none;
}

.filter-term:hover {
    text-decoration: none;
    color: #fff !important;
}

.filter-term:after {
    content: "X";
    color: #fff;
    padding-left: 5px;
    font-weight: bold;
}

.commentBox textarea {
    width: 80%;
    height: 70px;
}

.actionButtons {
    float: right;
    padding-top: 20px;
}

.actionButtons a:hover {
    background: #555;
}

.maincontent ul {
    list-style-position: inside;
}

.hasDatepicker {

}



.hideOnLoad {
    display: none;
}

.formLink {

}

.mainSprintSelector {
    font-size: var(--font-size-l);
    height: 30px;
    background: none;
    border: 0px;
    margin-bottom: 0px;
    box-shadow:none;
}

.mainSprintSelector small {
    color: #666;
}

.center {
    text-align: center;
}

.ui-datepicker-calendar td a.ui-state-active {
    background: #666;
    color: #fff;
}

.popover.bottom {
    margin-top: 30px;
    margin-left: 30px;
}

.label-primary:hover {
    background: #666;
}

.quickAddLink {
    display: block;
    padding: 5px;
    margin-bottom: 5px;
    border-radius:var(--box-radius);
}

.quickAddLink:hover {
    background: var(--neutral);
    text-decoration: none;
}

div.color-picker {
    background: #fff;
    border: 0px;
    padding: 10px 10px 0px 10px;
    box-shadow: var(--regular-shadow);
    z-index: 10000008;
    border-radius:var(--element-radius);
}

div.color-picker ul li {
    margin-right: 10px;
    margin-bottom:10px;
    border-radius: 20px;
    width: 30px;
    height: 30px;
    border-right: 0px solid #000;
    border-bottom: 0px solid #000;
}

div.color-picker ul li:hover {
    box-shadow:var(--regular-shadow);
}


#sortableSprint {
    border: 5px dotted #ccc;
    padding: 5px;
    margin: 10px 0px 50px 0px;
    min-height: 150px;
}

.sortableTicketList .ticketBox small {
    font-size: var(--font-size-s);
    color: var(--secondary-font-color);
    font-weight:lighter;
}

.sortableTicketList .ticketBox li {
    list-style: none;

    display: list-item;
}

.sortableTicketList .ticketBox li:hover {
    background: none;
}

.ui-state-highlight {
    background: var(--neutral);
    border: 1px dashed var(--main-border-color);
    visibility: visible;
    /* transition position changing */
    -webkit-transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
}

.currentSprint {
    margin-top: -12px;
    display: block;
}

.btn-file > input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    transform: translate(-300px, 0) scale(4);
    font-size: 24px;
    direction: ltr;
    cursor: pointer;
}

.uneditable-input {
    min-height: 30px;
}

.ticketBox a.userPopover {
    color: var(--primary-font-color);
}

.ticketBox a.userPopover:hover {
    text-decoration: none;
}

.backBtn {
    font-size: var(--font-size-l);
}

.padding-top {
    padding-top: 15px;
}

.padding-top-sm {
    padding-top: 5px;
}

.infoToolTip {
    font-size: var(--font-size-xl);
}

.infoToolTip:hover {
    text-decoration: none;
}

body a.delete,
.ui-tabs-panel .dropdown-menu li a.delete{
    color: var(--dark-red);
}

body .ui-tabs-panel {
    color:var(--primary-font-color);
}

.emptyChartContainer {
    min-height: 290px;
    text-align: center;
    padding-top: 75px;
}

input.small {
    width: 30px;
    margin-bottom: 2px;
}

/* Helpers */
.inline {
    display: inline-block;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.right {
    float: right;
}

.moveable.right,
.ticketBox.right {
    float: none;
}

.StripeElement {
    background-color: white;
    line-height: 20px;
    height: 30px;
    padding: 6px 6px;
    border: 1px solid #cccccc;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement#card-expiry {
    width: 100px;
    float: left;
}

.StripeElement#card-cvc {
    width: 100px;
    float: right;
}

.StripeElement iframe {
    position: absolute;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: var(--red);
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}





body::after {
    opacity: 0;
}



.padding-md {
    padding: 15px;
}

.padding-lg {
    padding: 30px;
}

.onboarding img {
    box-shadow: var(--regular-shadow);
    margin: 15px 0px;
    border: 1px solid #ccc;
}

.bigIcon {
    font-size: 40px;
}

.currentSprint select,
.mainSprintSelector {
    max-width: 220px;
}

.tooltip {
    z-index: 1000010;
}

.leftpanel .promotion {
    position: absolute;
    bottom: -20px;
    left: 0px;
    background: #555;
    box-shadow: inset 0px 0px 5px #111;
    color: #f3f4f5;
    padding: 10px 10px;
    margin: 20px 0px;
    border: 1px solid #555;
    width: 100%;
}

.leftpanel .promotion i {
    font-size: 24px;
    float: left;
    margin-right: 10px;
    line-height: 45px;
}

.leftpanel .promotion a {
    color: #f3f4f5;
}

.widgettitle .editHeadline {
    float: right;
    margin-right: -5px;
    color: #aaa;
    width: 24px;
    line-height: 20px;
    text-align: center;
    padding-top: 1px;
    border-radius: 5px;
}

body .widgettitle .editHeadline:hover {
    color: var(--accent1);
    background: var(--col-hover-bg);
}

.mce-window {
    z-index: 1000005 !important;
}

.popover-content {
    min-width: 150px;
}

.showDialogOnLoad {
}





.svgContainer {
    margin:auto;
    max-width:200px;
    margin-bottom:20px;
}

.svgContainer svg {
    max-width: 100%;
    height: auto;
}

.svgContainer.fixedHeight svg {
    width:auto;
    height:100px;
}

.loading {
    width:100%;
    text-align:center;
    background:url(../../images/svg/loading-animation.svg) center center no-repeat;
    min-height:31px;
    background-size:31px 31px;
    display:none;
}

.border-bottom {
    border-bottom:1px solid var(--neutral);
    padding-bottom:5px;
    margin-bottom:10px;
}

.border-top {
    border-top:1px solid var(--neutral);
    padding-top:5px;
    margin-top:5px;
}

.commentMain {
    width:calc(100% - 50px);
    margin-bottom:10px;
    float:left;
}

.commentContent {
    background:var(--col-title-bg);
    border-radius:var(--box-radius);
    width:100%;
    padding:12px 15px;
    font-size:var(--base-font-size);
}

.commentContent.statusUpdate {
    background:var(--layered-background);
    border: 1px solid var(--main-border-color);
}

.commentContent.statusUpdate h3 {
    margin-bottom:8px;
    font-weight: bold;
}

.commentContent .text {
    text-align:left;
    margin: 0;
}

#mainToggler {
    margin-bottom:15px;
    display:block;
}

.commentContent .name {
    font-weight:bold;
    display:block;
    margin-bottom:5px;
}

.commentContent .commentDate {
    font-size:var(--font-size-s);
}

.commentImage {
    margin-top:0px;
    float:left;
    margin-right:10px;

}

.commentImage img {
    border-radius:50%;
    width:38px;
    height:38px;
    border:1px solid var(--main-border-color);
    background:var(--secondary-background);
}
.commentLinks {

}

.commentLinks a {
    display:inline-block;
    margin-right:10px;
    margin-bottom:10px;
    font-size:var(--font-size-s);
}

.commentMain .replies {
    padding-left:10px;
}

.commentMain .replies .commentMain{
    margin-bottom:0px;
}

.commentMain .replies .commentMain:last-child {
    margin-bottom:0px;
}

.commentReply {
    width:calc(100% - 50px);
    float:left;
}

.commentReply input[type="submit"] {
    margin-top:10px;
    margin-bottom:20px;
}


.sortHandle {
    font-size:16px;
    color:#ccc;
    padding-left:15px;
}

.ui-tabs-panel {
    padding:15px 0px;
}

.articleWrapper {
    max-width:980px;
    margin:auto;
    text-align:left;
}

.articleHeadline {
    font-size:var(--font-size-xxxl);
    margin-bottom:10px;
}

.articleHeadline .far {

}

.articleMeta {
    font-style: italic;
    margin-bottom:25px;
}

.articleMeta .metaContent {
    opacity:0.7;
}

.articleMeta .tagsinput.readonly {
    width:auto !important;
    height:auto !important;
    margin: 0px 0px;
    vertical-align: middle;
    color:var(--primary-font-color);
    opacity:0.7;
}

.articleMeta div.tagsinput.readonly span.tag {
    margin:0px 5px;
    margin-left:0px;
    padding:0px;
    background:none;
    color:var(--primary-font-color);
    opacity:0.7;
}

.articleBody {
    margin-bottom:25px;
}

.teamBox {
    padding-top:15px;
}

.profileBox {
    border-radius: var(--box-radius);
    text-align:center;
    margin-bottom: 20px;
    padding:10px;
    margin-top:20px;
    background: var(--kanban-card-bg);
}


.profileBox .commentImage {
    float:none;
    margin:0px;
    margin-top:-35px;
    margin-bottom:10px;
}

.profileBox .commentImage img {
    width:50px;
    height:50px;
    border-radius:50px;
}

.profileBox .commentImage i {
    display:inline-block;
    border:1px solid var(--main-border-color);
    border-radius:50px;
    width:50px;
    line-height:50px;
    font-size:28px;
    background:var(--secondary-background);
    color:var(--primary-color);
}

.profileBox .userName {
    font-size:var(--font-size-l);
    display:block;
}

a.anchor {
    display: block;
    position: relative;
    top: -70px;
    visibility: hidden;
}

a.titleInsertLink,
a.titleInsertLink:link{
    float:right;
    color:#aaa;
    font-size:var(--font-size-s);
}

a.titleInsertLink:hover {
    color:var(--primary-color);
    padding-top: 3px;
}

#pwStrength {
    color:#fff;
    padding:0px 5px;
    border-radius:var(--box-radius-small);
    font-size:var(--font-size-xs);
    width:206px;
    margin-top:-2px;
    margin-bottom:10px;
}


.scrollableItemList {
    width:100%;
    height:80%;
    max-height:500px;
    overflow:auto;
    padding:10px;
    padding-top:0px;
    border:1px solid var(--main-border-color);
    border-radius: var(--box-radius-small);
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border linear .2s, box-shadow linear .2s;
    -moz-transition: border linear .2s, box-shadow linear .2s;
    -o-transition: border linear .2s, box-shadow linear .2s;
    transition: border linear .2s, box-shadow linear .2s;
}

.scrollableItemList .item {
    clear:both;
    border-bottom:1px solid var(--main-border-color);
    padding: 5px 0px;
}

.scrollableItemList .item input[type=checkbox] {
    float:left;
    margin-right:5px;
}

.scrollableItemList .item label {
    width:75%;
}

.scrollableItemList h3 {
    clear:both;
    margin-bottom:0px;
    margin-top:15px;
}

.scrollableItemList .item label {
    vertical-align: middle;

}

@media print {
    .leftpanel {
        display:none;
    }

    .header {
        display:none;
    }
}


.mirrorElement {
    transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
    display: Inline-block;
}

.projectBox {
    border:1px solid var(--main-border-color);
    border-radius:var(--box-radius);
    box-shadow:var(--regular-shadow);
    padding:10px;
    margin-bottom:15px;
    background:var(--kanban-card-bg);
}

.projectBox small {
    color:var(--kanban-col-title-color);
}

.projectBox .lastStatus [class*="commentStatus-"]{
    padding-left:10px;
}

.projectBox .projectAvatar,
.ticketBox .projectAvatar{
    width:50px;
    height:50px;
    float:left;
    margin-right:10px;
    text-align:center;
    border-radius:var(--box-radius-small);
    background:var(--element-gradient);
}

.projectBox .projectAvatar img,
.ticketBox .projectAvatar img{
    width:50px;
    border-radius:var(--box-radius-small);
}

.projectBox .projectAvatar .fa {

    color:var(--main-action-color);
    font-size: 23px;
    line-height: 50px;
}

.stickyColumn {
    position:sticky;
    top:60px
}

h3.todaysDate {
    font-weight:normal;
}

.ui-resizable-handle.ui-resizable-w {
    width:15px;
    height:100%;
    border-left:1px dashed var(--main-border-color);
    cursor:ew-resize;
    position:absolute;
    background:var(--secondary-background);
    z-index:5 !important;
}


.resizable {

    background: var(--secondary-background);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    padding-left:15px;
    margin-left:25px;
}

.flexParent{
    display:flex;
}

.box {
    display: flex;
    flex: 1 1;
    position:relative;

}

#ticketContent {
    margin-left: 0px;
    padding: 15px;

    height: 100%;
    min-height: 600px;
    background: var(--secondary-background);
    border-radius: 10px;
}

.stickyRow {
    position: sticky;
    top: 65px;
}

.subtaskLineLink {
    cursor:pointer;
}

.bigNumberBox {
    box-shadow: var(--large-shadow);
    border-radius:var(--box-radius);
    padding:15px;
    color:var(--main-titles-color);
    background:var(--secondary-background);
}

.bigNumberBox .title {
    display:inline-block;
    padding-bottom:5px;

}
.bigNumberBox .content {
    font-size:36px
}

.verticalTab {
    padding: 0;
    margin-bottom: 5px;
    text-align: center;
    overflow: hidden;
    background: var(--kanban-col-title-bg);
    border-radius: var(--box-radius);
    min-height:100px;
}

.verticalTab .content {
    transform: translateX(-50%) translateY(-50%) rotate(-.25turn);
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    font-size:var(--font-size-l);
}

.minCalendar #calendar {
    padding-top:10px;
    position: relative;
    z-index:5;
}


.minCalendar .fc .fc-toolbar-title {
    font-size: var(--font-size-xl);
}

.minCalendar .fc .fc-multimonth {
    height:100%;
}

.minCalendar .fc .fc-multimonth,
.minCalendar .fc-theme-standard td, .fc-theme-standard th{
    border:none;
}

.minCalendar .fc .fc-daygrid-day-top {
    flex-direction: column;
    text-align:center;
}

.minCalendar .fc .fc-daygrid-day-bottom {
    text-align:center;
    margin-top:-10px !important;
}

.minCalendar .fc .fc-daygrid-more-link {
    font-size:0px;
    float:none;
}

.minCalendar .fc .fc-daygrid-more-link:after {
    content: "•";
    font-size:30px;
}

.minCalendar .fc .fc-multimonth-title {
    padding:0px 0px 10px 0px;
    text-align:left;
    font-size: var(--font-size-xl);
    font-weight:normal;
    display:none;
}

.minCalendar .fc .fc-multimonth-singlecol .fc-multimonth-header {
    background:none;
}

.minCalendar .fc-theme-standard th.fc-col-header-cell:first-child {
    border-radius:10px 0px 0px 0px;
}

.minCalendar .fc-theme-standard th.fc-col-header-cell:last-child {
    border-radius:0px 10px 0px 0px;
}

.minCalendar .fc-theme-standard .fc-popover {
    border-radius:var(--box-radius);
    max-width: 60%;
}

.minCalendar .fc-popover-body  .fc-h-event .fc-event-title-container {
    padding-left:5px;
}

.minCalendar .fc-popover-body  .fc-event {
    margin-bottom:2px;
    margin-left:0px !important;
}

.minCalendar .fc-popover-body .fc-daygrid-event {
    border-radius:0px !important;
}

.minCalendar .fc .fc-daygrid-day.fc-day-today {
    border-radius:var(--element-radius);
}

.minCalendar .fc .fc-multimonth-daygrid {
    background:var(--layered-background);
}

.minCalendar .fc-multimonth-daygrid-table .fc-daygrid-day-events .fc-daygrid-event-harness {

}

.maincontent .fc .fc-timegrid-col.fc-day-today,
.maincontent .fc .fc-daygrid-day.fc-day-today,
.maincontent  .fc .fc-button-primary {
    background-color: var(--col-title-bg);
    color:var(--primary-font-color);
    border-radius: var(--element-radius) !important;
}

.maincontent .fc .fc-day-today .fc-daygrid-day-number {
    color:var(--main-action-color);
    background:var(--accent1);
    border-radius: var(--element-radius) !important;
}


.maincontent .fc .fc-button-primary:focus,
.maincontent .fc .fc-button-primary:active {
    box-shadow: none;
}

.maincontent .fc .fc-button-primary:not(:disabled).fc-button-active,
.maincontent .fc .fc-button-primary:not(:disabled):active,
.maincontent .fc .fc-button-primary:not(:disabled):hover {
    background:var(--secondary-action-hover-bg);
    color:var(--secondary-action-hover-color);
}

.maincontent .fc-multimonth-header-table .fc-col-header-cell {
    padding:5px;
}

.maincontent .fc-list-event {
    background:var(--secondary-background);

}

.maincontent .fc-list-event td {
    padding:5px;
}

.maincontent .fc-list-event td.fc-list-event-time {
    padding-left:15px;
}

.maincontent .fc-v-event {
    border:none;
    border-left:5px solid var(--fc-event-border-color);
}

.maincontent .fc-v-event .fc-event-main-frame {
    padding-left:5px;
}

.maincontent .fc-timegrid-event {
    border-radius: var(--element-radius);
}

.maincontent .fc-timegrid-event-harness-inset .fc-timegrid-event,
.maincontent .fc-timegrid-event.fc-event-mirror {
    box-shadow:var(--regular-shadow);
}

.maincontent .fc-timegrid-event .fc-event-main {
    padding: 3px 1px 0px;
}

.maincontent .fc-event.locked:after {
    content: "🔒";
    background:var(--layered-background);
    padding: 1px 4px;
    top: 2px;
    border-radius: 50%;
    position: absolute;
    right: 2px;
}

.maincontent tr.fc-event {
    position:relative;
}

.ticketBox {
    cursor:grab;
}

.ticketBox.fc-event-dragging,
.ticketBox.ui-draggable-dragging {
    background:var(--secondary-background);
    padding:10px;
    border-radius:var(--box-radius);
    opacity:0.7;
}

.ticketBox.fc-event-dragging .dropdown a,
.ticketBox.ui-draggable-dragging .dropdown a{
    color:var(--main-action-color);
}

.ticketBox.fc-event-dragging:before,
.ticketBox.ui-draggable-dragging:before{
    content: "Drag me to the calendar";
    background: #000;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    top: -38px;
    text-align: center;
    width: 200px;
    left: 50%;
    margin-left: -100px;
}

.profileImage {

    overflow:hidden;
    display:inline-block;
    vertical-align: middle;
    margin-right:5px;
    padding:0px;

}

.profileImage img {
    width:30px;
    height:30px;
    border-radius:20px;
    padding:1px;
    border:1px solid var(--neutral);
}





.uppy-StatusBar-progress {
    background-color: var(--primary-color);
}

.uppy-StatusBar.is-error .uppy-StatusBar-statusIndicator {
    color:var(--red);
}

.uppy-StatusBar.is-complete .uppy-StatusBar-statusIndicator {
    color:var(--green);
}

.uppy-StatusBar.is-error .uppy-StatusBar-progress,
.uppy-StatusBar-actionBtn--retry,
.uppy-StatusBar-actionBtn--retry:hover{
    background-color: var(--red);
    color:#fff !important;
}

.uppy-StatusBar.is-complete .uppy-StatusBar-progress {
    background-color: var(--green);
}

.uppy-StatusBar.is-preprocessing .uppy-StatusBar-progress,
.uppy-StatusBar.is-postprocessing .uppy-StatusBar-progress {
    background-color:var(--yellow);
}

.uppy-StatusBar-actionBtn,
.uppy-StatusBar:not(.is-waiting) .uppy-StatusBar-actionBtn--upload{
    color:var(--primary-color);
}

.uppy-StatusBar-actionBtn--done:hover {
    color:var(--dark-blue);
}

[data-uppy-theme=dark] .uppy-StatusBar-actionBtn--done {
    color:var(--primary-color);
}

.uppy-StatusBar-spinner {
    fill:var(--primary-color);
}

.uppy-Webcam-button svg{
    fill:currentColor
}

.htmx-indicator,
.htmx-indicator-small {
    display:none;
}

.htmx-indicator img,
.htmx-indicator-small img{
    width:75px;
    margin:auto;
}

.htmx-request.htmx-indicator {
    display:block;
}

.htmx-request.htmx-indicator-small {
    display:inline-block;
}

/* Hide or show the actual/previous content based on htmx indicator visibility */
.htmx-indicator.htmx-loaded-content {
    display:block;
    opacity: 1;
}

.htmx-request.htmx-indicator.htmx-loaded-content {
    display: none;
    opacity:0;
}

h4 .helperTooltip,
.helperTooltip {
    float:right;
    color:var(--primary-font-color);
    cursor:pointer;
}

.color-circle {
    display: inline-block;
    margin-right: 25px;
    text-align: center;
}

.color-circle .color {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid var(--main-border-color);
    display: block;
    margin:auto;
}

.bigNumberBox {
    border-radius: 10px;
    background: linear-gradient(180deg, var(--col-row-striped) -85%, transparent 100%);
    backdrop-filter: var(--glass-blur);
    -webkit-backdrop-filter: var(--glass-blur);
    box-shadow: var(--large-shadow);
    border: 1px solid var( --glass-border);
}

.bigNumberBox .icon {
    float:left;
    margin-right:10px;
}

.bigNumberBox .icon .fa {
    font-size: 40px;
    color: var(--main-titles-color);
}


.bigNumberBoxNumber {
    font-size: 36px;
    color: var(--main-titles-color);
    font-weight: normal;
    text-shadow: var(--min-shadow);
    line-height: 1;
}

.bigNumberBoxText {
    color: var(--main-titles-color);
    font-weight:bold;
}

.maincontent .infoIcon {
    font-size: var(--font-size-l);
    color: var(--interactive-icon-color);
    vertical-align: text-top;
    cursor:pointer;
}

.favoriteStar {

}

.favoriteStar.go {
    animation: spin .6s ease-in-out infinite;;

}

@keyframes spin {
    from {
        transform: rotate(0deg) scale(1);
        opacity:1
    }
    to {
        transform: rotate(360deg) scale(1);
        opacity:0.5;

    }
}

.smooth {
    transition: all 1s ease-in;
}


@keyframes fade-in {
    from { opacity: 0; }
}

@keyframes fade-out {
    to { opacity: 0; }
}

@keyframes slide-from-right {
    from { transform: translateX(90px); }
}

@keyframes slide-to-left {
    to { transform: translateX(-90px); }
}

.slide-it {
    view-transition-name: slide-it;
}


::view-transition-old(fade-it) {
    animation: 180ms cubic-bezier(0.4, 0, 1, 1) both fade-out,
    600ms cubic-bezier(0.4, 0, 0.2, 1) both fade-in;
}
::view-transition-new(fade-it) {
    animation: 420ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in,
    600ms cubic-bezier(0.4, 0, 0.2, 1) both fade-out;
}

body .fancyLink {
    background-color: var(--accent1);
    font-weight:bold;

    /* Create the gradient. */
    background-image: var(--element-gradient);

    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

body a.btn.btn-fancy {
    background-image: var(--element-gradient);
    color:var(--main-action-color);
}

body a.btn-link.btn-fancy {
    background-color: var(--accent1);
    font-weight:bold;

    /* Create the gradient. */
    background-image: var(--element-gradient);

    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

body a.btn-link.btn-fancy:hover {
    background-image: var(--element-gradient);
    color:var(--main-action-color);
    -webkit-text-fill-color: inherit;
    border: 1px solid var(--main-action-color);
}


.aiPriority > li {
    margin-bottom: 10px;
    background-image: var(--element-gradient);
    padding: 2px;
    border-radius: 7px;
    box-shadow: 0 7px 0 rgba(54, 45, 89, 0.15), -3px -3px 0 0px var(--accent1), 0 0 0 3px var(--accent2);
}

.aiPriority .ticketBox {
    margin:0px;
    background:var(--secondary-background);
}


.aiPriority li:after {

    content: "";

}


.xOut {
    font-size: var(--font-size-xxl);
    text-indent: 0;
    color: #aaa !important;
    border-radius: 5px;
    height: 25px;
    line-height: 26px;
    text-align: center;
    width: 25px;
    float:right;
}

.xOut:hover {
    text-decoration: none;
    color: var(--primary-font-color) !important;
    background-color: var(--col-hover-bg);
}

.indicatorCircle {
    display:inline-block;
    background:#ccc;
    width:15px;
    height:15px;
    border-radius:50%;
    margin-right:10px;
    vertical-align: sub;
}

.simpleList {
    list-style-type: none;
}

.simpleList li {

    padding-bottom:5px;
}


.fc-v-event .fc-event-main-frame {
    flex-direction: column-reverse;
}

.modal-backdrop {
    display:none;
}

.projectBox {
    color:var(--primary-font-color);
}

.widgetContent {
    padding:0px 2px;
}
